import { useLocation } from "@reach/router";
import * as React from "react";
import { Spinner } from "react-bootstrap";
// import Footer from '../components/Footer/Footer'
// import Header from "../components/Header/Header";
// import Layout from "../components/Layout/Layout";

const NotFoundPage = () => {
  // const [isLoading, setisLoading] = React.useState(true);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setisLoading(false);
  //   }, 3000);
  // }, []);
  return (
    <>
      {/* <Header /> */}
      <div className="notFoundsec">
        <div className="clearfix"></div>
        <div className="mainsec">
          <div className="loadingSec">
            {/* Not found */}
            <Spinner animation="border" variant="dark" />{' '}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
